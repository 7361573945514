.projects-section {
  /* Remove the min-height to allow the section to expand dynamically */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 50px;
  box-sizing: border-box;
}

.projects-list {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 40px;
  max-width: 1200px;
  width: 100%;
  /* Allow the section to expand vertically if cards overflow */
}



.project-card:hover {
  transform: scale(1.05);
  box-shadow: 0px 8px 20px rgba(0, 0, 0, 0.7);
}

.project-card h3,
.project-card p,
.project-card a {
  transition: opacity 0.3s ease;
}

.project-card iframe {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  transition: opacity 0.3s ease;
  pointer-events: none; /* Prevent iframe from capturing hover events */
  border: none;
}

.project-card:hover h3,
.project-card:hover p,
.project-card:hover a {
  opacity: 0;
}

.project-card:hover iframe {
  opacity: 1;
}

.view-project-link {
  color: #ffcc00;
  text-decoration: none;
  font-size: 1.1rem;
  transition: color 0.3s ease;
}

.view-project-link:hover {
  color: #ffa500;
}

/* Mobile-specific styles */
@media (max-width: 768px) {
  .projects-list {
    grid-template-columns: 1fr; /* Stack project cards vertically on mobile */
  }

  .project-card {
    background: linear-gradient(135deg, rgba(0, 0, 0, 0.1), rgb(6, 144, 224));
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
    padding: 30px 15px;
  }

  .project-card:hover {
    transform: scale(1.03);
  }

  .project-card h3 {
    font-size: 1.2rem;
  }

  .view-project-link {
    font-size: 1rem;
  }

  .project-card iframe {
    height: 200px; /* Set a reasonable height for iframe on mobile */
  }
}
