.about-section {
  height: 100vh; /* Full viewport height */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: white;
  padding: 50px;
}

.about-section h2 {
  font-size: 2.5rem;
  margin-bottom: 20px;
  text-shadow: 2px 2px 5px rgba(0, 0, 0, 0.7);
}

.about-section p {
  max-width: 800px;
  text-align: center;
  font-size: 1.2rem;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.8);
  background: linear-gradient(135deg, rgba(0, 0, 0, 0.08), rgb(6, 144, 224)); /* Adjust gradient for mobile */
}

#about p {
  font-size: 1rem;
  text-shadow: 2px 2px 5px rgba(0, 0, 0, 2);
}
#about {
  margin-bottom: 50px; /* Adds space at the bottom of the About section */
}

@media (max-width: 768px) {
  #about {
    margin-bottom: 20px; /* Adjust space for tablet */
  }
}

@media (max-width: 480px) {
  #about {
    margin-bottom: 190px; /* Adjust space for mobile */
  }
}