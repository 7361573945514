header {
    background-color: #333;
    padding: 10px;
    color: white;
  }
  
  header nav ul {
    list-style-type: none;
  }
  
  header nav ul li {
    display: inline;
    margin-right: 10px;
    cursor: pointer;
  }
  
  header nav ul li a {
    color: white;
    text-decoration: none;
  }
  